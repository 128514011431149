import React from 'react';
import '../App.css';
import './HeroIcons.css';
import Fade from 'react-reveal/Fade';

import {Link} from 'react-router-dom';

function HeroIcons() {
  return (
    <> 
    <div className='hero-icons' id="h-icons">
      <div className='hero-icons-container'>
  
        <div className='icon'>
            <img src="/website.png" width="10%" style={{marginLeft: '45%'}} className='img'/>
           <Fade left> <h1>Tworzenie stron internetowych</h1>
            <hr></hr>
            <h3>Oferujemy zaprojektowanie strony internetowej dla wszystkich. Specjalizujemy się w stronach statycznych. Możemy zaimplementować również sklep internetowy z konkretnymi funkcjonalnościami. Cena projektu uwarunkowana jest od ilości podstron oraz rodzaju strony.
Dodatkowo oferujemy projekt graficzny logo oraz zrobienia i obróbki zdjęć do tworzonej strony. W cenie oferujemy stworzenie wizytówki Google, pozycjonowanie jej oraz podstawowe pozycjonowanie strony w wyszukiwarkach.</h3>
              </Fade>   <Link to="/budowa-stron"><button type="button" > Więcej</button></Link>
           
        </div> 
     
    
        <div className='icon'>
           <img src="/server.png" width="10%"  style={{marginLeft: '45%'}} className='img'/>
             <Fade right> <h1>Hosting</h1> 
            <hr></hr>
            <h3> Świadczymy hosting stworzonych przez nas stron WWW oraz dajemy możliwość przeniesienia istniejącej witryny do naszego hostingu. Hosting jest usługą polegającą na udostępnieniu określonego miejsca na serwerze w celu na przykład wdrożenia gotowej strony. Dodatkowo w ramach naszego hostingu, możemy utworzyć imienne nazwy e-mail od istniejącej domeny, również będzie nadzorowana poprawność przesyłania wiadomości e-mail na stronach www. </h3>
          </Fade>   <Link to="/hosting_www"><button type="button" > Więcej</button></Link>
        </div>
       
        <div className='icon'>
            <img src="/help.png" width="10%" style={{marginLeft: '45%'}} className='img'/>
            <Fade left> <h1>Panel klienta</h1>

            <hr></hr>
            <h3>Nasz serwis posiada panel klienta, w którym, zalogowanym użytkownikom dajemy możliwość wglądu w wykupione usługi, konfigurację, śledzenie statusu płatnośći, faktury oraz edycje swoich danych. </h3>
            </Fade> <Link to="/login"><button type="button" > Więcej</button></Link>
        </div>
       
       
        <div className='icon'>
             <img src="/monitor.png" width="10%" style={{marginLeft: '45%'}} className='img'/>
            <Fade right> <h1>Kompleksowa obsługa IT</h1>
            <hr></hr>
            <h3>Świadczymy usługi firmom (możliwośc wystawienia faktury), jak i osobom fizycznym. W skład naszej oferty wchodzi m.in: diagnostyka sprzętu komputerowego, mobilnego,  tabletów, również pielęgnacja (czyszczenie, formatowanie), instalacja programów branzowych na zyczenie klienta, wdrażanie antywirusów na życzenie klienta, słuzymy doradztwem w zakupie sprzętu, zapewniamy bezpieczeństwo IT ( tworzenie backup, odzyskiwanie danych, usuwanie).</h3>
            </Fade> <Link to="/obslugaIT"><button type="button" > Więcej</button></Link>
        </div>
       
     </div>
     <div className='hero-bottom-white'></div>
      </div>
    </>
  );
}
export default HeroIcons;