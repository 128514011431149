import React, { Component } from "react";
import '../../App.css';
import { useState, useEffect } from "react";



class Status extends React.Component {


  constructor(x,y){
      super(x,y)

      this.state={
         message: x,
         code:y
      }


  }
}
export default Status;