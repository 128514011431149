import React, { Component } from "react";
import '../../App.css';
import './NewOptionSoon.css'



class  NewOptionSoon extends Component {

    render() {

        return (
          <>
          <div className="new-conatiner">
            <h1>Funkcja niedługo dostępna...</h1>
          </div>
        </>
        );
  }
  }
  export default NewOptionSoon;