import { render } from "@testing-library/react";
import { useLocation } from 'react-router-dom';
import React, { Component } from "react";
import '../../App.css';
import './RegisterSuccess.css';
import Fade from 'react-reveal/Fade';

class  RegisterSuccess extends Component {

   
    render(){
  return (
    
   <Fade top><div className='register-success-div'>
  
          <h3 style={{width: "100%", margin: "1%", display:"block", textAlign:"center"}}>Konto zostało stworzone.<br></br>Kliknij w link aktywacyjny wysłany na adres mejlowy, podaną przy rejestracji. <br></br> </h3>
          <br></br>
<img src="/checked.png" style={{width: "20%",margin:"0 auto",display:"block"}}></img>
          
    </div></Fade>
  );
}
}
export default RegisterSuccess;