import React, { Component } from "react";

import './About.css'
import Fade from 'react-reveal/Fade';

function About() {
  return (
    <>
    <div className='about-container'>

      <div className='about-kim-container'>
            <div className='about-kim'>
              <h1 style={{color:"grey"}}>Kim jesteśmy?</h1>
              <h2>Działamy w naszej firmę <span style={{color:"rgb(94, 182, 5)"}}>we dwójkę</span>. 
                Tuż po studiach postanowiliśmy razem otworzyc firmę informatyczną. Troche projektowania stron, hosting oraz wsparcie techniczne osób prywatnych i firm. Musimy tutaj podkreślić że dopiero zaczynamy naszą przygodę, ale jesteśmy zdeterminowani i pracowici. Szanujemy czyjś czas. Dla nas <span style={{color:"rgb(94, 182, 5)"}}>najważniejsze jest zadowolenie klienta.</span></h2> 
          </div>
          <div className='about-kim-text'>
                  <Fade right>
                  <img src="idea.png" alt="https://www.flaticon.com/" width="80%"  />
                  </Fade></div>
    </div>

    <div className='about-co-container'>
          <div className='about-co'>
          <h1 style={{color:"grey"}}>Co robimy?</h1>
               <h2>
              <p> <img src="check.png" alt="https://www.flaticon.com/" className='check' />     tworzymy strony www</p>
              <p><img src="check.png" alt="https://www.flaticon.com/" className='check' />  zapewniamy podstawowe pozycjonowanie stron SEO</p>
              <p><img src="check.png" alt="https://www.flaticon.com/"  className='check' />  hosting stron</p>
              <p><img src="check.png" alt="https://www.flaticon.com/" className='check' />  pomoc zdalną firmom oraz osobom prywatnym</p>
              <p><img src="check.png" alt="https://www.flaticon.com/" className='check' />  obsługę IT na terenie województwa Podlaskiego (m.in. usuwanie usterek, instalacja oprogramowania, itp)</p></h2>
            
          </div>
     
        </div>
    </div>
    </>
  );
}
export default About;