import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Rodo from './components/pages/Rodo';
import Kontakt from './components/pages/Kontakt';
import HostingWWW from './components/dropdown/HostingWWW';
import HostingVPS from './components/dropdown/HostingVPS';
import ObslugaIT from './components/dropdown/ObslugaIT';
import Footer from './components/Footer';
import {BrowserRouter , Switch, Route} from 'react-router-dom';
import './App.css';
import StronyWWW from './components/dropdown/StronyWWW';
import HelpDeskAPI from './components/pages/HelpDeskAPI';
import Register from './components/dropdown/Register';
import Login from './components/dropdown/Login';
import NewOptionSoon from './components/dropdown/NewOptionSoon';
import RegisterSuccess from './components/dropdown/RegisterSuccess';
import ForgotPass from './components/dropdown/ForgotPass';

function MainApp() {

  return (
   <>
     <BrowserRouter> 
    <Navbar sticky="top"/>
    <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/budowa-stron' component={StronyWWW} />
          <Route path='/hosting-www' component={HostingWWW} />
          <Route path='/hosting-VPS' component={HostingVPS} />
          <Route path='/obsluga-IT' component={ObslugaIT} />
          <Route path='/about' component={About} />
          <Route path='/rodo' component={Rodo} />
          <Route path='/kontakt' component={Kontakt} />
          <Route path='/register' component={Register} />
          {/* <Route path="/login" component={Login} /> */}
          <Route path="/login" component={NewOptionSoon} />
          <Route path="/success" component={RegisterSuccess} />
          <Route path="/forgot-password" component={ForgotPass} />
     </Switch>
     <Footer/>
   
    </ BrowserRouter>
      </>  
  );
}

export default MainApp;
