import React, { Component } from "react";
import '../../App.css';
import './Kontakt.css'
import Fade from 'react-reveal/Fade';
import { useState } from "react";
import {Link} from "react-router-dom"
import { render } from "@testing-library/react";
import Swal from 'sweetalert2'



class  Kontakt extends Component {
  constructor(props) {
    super(props);
    this.state = {message: '',
                    name: '',
                    phone:'',
                    email: '',
                  checkedSubmit: false, 
                  subject:''
                 };
  }
  handleSubmit(event) {
    this.setState({
      checkedSubmit: !this.state.checkedSubmit    
  })
  }
  handleChange = (e) => {
    this.setState({subject: e.target.value})
  }
 
  nameChange = (event) => {
    this.setState({name: event.target.value})
  }
 
  emailChange = (event) => {
    this.setState({email: event.target.value})
  }

  phoneChange = (event) => {
    this.setState({phone: event.target.value})
  }
 
  messageChange = (event) => {
    this.setState({message: event.target.value})
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const templateId = 'template_a0pg2yc';

    this.sendFeedback(templateId, {
      subject: this.state.subject,
      message: this.state.message, 
      name: this.state.name, 
      email: this.state.email,
      phone: this.state.phone
     }
)

}



sendFeedback = (templateId, variables) => {
  window.emailjs.send(
    'service_d39sd46', templateId,
    variables
    ).then(res => {
      console.log("succes") ;
                Swal.fire({
                    title: 'Wiadomość została wysłana',
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                    confirmButtonColor:"#C0C0C0",
                    icon: 'success'
                }
                ).then(res => {
                
                  setTimeout(() => {
                        window.location.reload();
                      }, 1500); console.log('wyslano');
                      console.log(this.state.subject)
                })
              }  
                    
    )
    .catch(err => {
      console.error('Email Error:', err)
    })
  
}
  

render() {

  return (
    <>
    <div className='contact-container'>
    <img src="/subpages_back_1.jpg" width="100%" className='img'/>
        <div className='image-title'>
      <Fade left>
      <div className='contact-img'>
      <img src="/chat.png" style={{width:"70%"}}></img></div></Fade>
      <Fade right>
          <div className='contact-text'>
          <br></br>
          <h1 className='h1-title'>Kontakt</h1>
          <br></br>
          <br></br>
          <h2><span style={{color:"rgb(94, 182, 5)", fontWeight:"bold"}}>Zostaw nam wiadomość</span></h2>
          <br></br>
            <h3>Masz pytania? </h3>
            <h3>Odnośnie współpracy lub wybranych usług?</h3>
            <h3>Możesz skorzystać z formularza kontaktowego poniżej.</h3>
            <h3>Odpowiemy na wiadomość jak najszybciej to będzie możliwe.</h3>
      </div>
      </Fade>
 </div>
           
      <div classname='contact-bottom'>
            
          <div className='text-mini-kafle'>
            <h1>Zapraszamy do kontaktu</h1>
          </div>

      <div classname='background' >
         <Fade left><div className='contact-mini-tel'> <h2>Zadzwoń!</h2><img src="/phone-black.png" style={{color:"white"}} width="20%"/><h2 ><a style={{color:"rgb(94, 182, 5)"}} href="tel:798 658 700">798 658 700</a></h2></div></Fade>
         <Fade right><div className='contact-mini-mail'><h2>Napisz!</h2><img src="/mail-black.png" width="20%" /><h2 ><a style={{color:"rgb(94, 182, 5)"}} href="mailto:kontakt@cloverit.pl">kontakt@cloverit.pl</a></h2></div></Fade>
       
      </div>

      <div className='contact-form'> <h4 style={{color:"rgb(94, 182, 5)", fontWeight:"900" }}>ZOSTAW NAM WIADOMOŚĆ</h4>
        <br></br>
        <h2>Formularz kontaktowy</h2>
            <br></br>
            <br></br>
        <div class="screen-body-item">
          <form class="app-form" onSubmit={this.handleSubmit}>
            <div class="app-form-group">
              <input class="app-form-control" type="text" onChange={this.nameChange} placeholder="IMIE" required/>
            </div>
            <div class="app-form-group">
              <input class="app-form-control" type="email" onChange={this.emailChange} placeholder="EMAIL" required/>
            </div>
            <div class="app-form-group">
              <input class="app-form-control" type="tel" onChange={this.phoneChange} placeholder="TELEFON"/>
            </div>
            <div class="app-form-group">
            <select class="app-form-control" onChange={this.handleChange} >
                <option  value="Pytanie"  id="input-field">PYTANIE ODNOŚNIE</option>
              <option value="StronaWWW">PPROJEKT STRONY WWW</option>
              <option value="Sklep">PROJEKT SKLEPU INTERNETOWEGO</option>
              <option value="Współpraca">WSPÓŁPRACA</option>
              <option value="Hosting">HOSTING</option>
              <option value="Help">POMOC ZDALNA</option>
              <option value="Inne">INNE</option>
              </select>
            </div>
            <div class="app-form-group message">
              <textarea class="app-form-control" onChange={this.messageChange} rows="8" cols="50" placeholder="WIADOMOŚĆ"/>
            </div>
            <div className='app-form-button'>
              <button type="submit"  checked={ this.state.checkedSubmit }  onChange={ this.handleSubmit }>Wyślij</button>
            </div>
          </form>
        </div>



      </div>
    </div>
    </div>
    </>
  );
}
}
export default Kontakt;