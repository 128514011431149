import React, {useState} from 'react';
import './Navbar.css';
import { ButtonHelp } from './ButtonHelp';
import {Link} from 'react-router-dom';
import Dropdown from './Dropdown';


function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [dropdown, setDropdown] = useState(false);
  
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
  
    const extendElement = () =>{

      dropdown ? setDropdown(false) : setDropdown(true);
    }

    const showButton = () => {
      if (window.innerWidth <= 960) {
        setButton(false);
      } else {
        setButton(true);
      }
    };
      const onMouseEnter = () => {
        if (window.innerWidth < 960) {
          setDropdown(false);
        } else {
          setDropdown(true);
        }
      };
    
      const onMouseLeave = () => {
        if (window.innerWidth < 960) {
          setDropdown(false);
        } else {
          setDropdown(false);
        }
    };
    
    window.addEventListener('resize', showButton);
      
    return (
        <>
        <div className='navbar' sticky="top">
            <div className='navbar-container'>
                <Link to='/' className='link-logo'>
                   <img src="/CLOVERIT_NEW.png" className='navbar-logo' ></img>
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}  >
                    <li className='nav-item' >
                        <Link to='/budowa-stron' className='nav-links' onClick={() => setClick(!click)}>
                          Budowa stron
                        </Link>
                    </li>
                    <li className='nav-item' >
                        <Link to='/hosting-www' className='nav-links' onClick={() => setClick(!click)}>
                          Hosting WWW
                        </Link>
                    </li>
                    <li className='nav-item' >
                        <Link to='/hosting-vps' className='nav-links' onClick={() => setClick(!click)}>
                          Hosting VPS
                        </Link>
                    </li>
                        <li className='nav-item' >
                            <Link  to='/obsluga-IT' className='nav-links' onClick={() => setClick(!click)}>
                              Pomoc IT
                            </Link>
                            
                        </li>
                    
                    <li className='nav-item' >
                        <Link to='/kontakt' className='nav-links' onClick={() => setClick(!click)}>
                          Kontakt
                        </Link>
                    </li>
                    <li >
              <Link
                to='/login'
                className='nav-links-mobile'
                onClick={() => setClick(!click)}

              >Logowanie/ Rejestracja
              </Link>
            </li> {button && <ButtonHelp buttonStyle='btn--outline'id="help">Logowanie/ Rejestracja</ButtonHelp>}
          </ul>
         
        </div>
        </div>
        </>
    )
}

export default Navbar;