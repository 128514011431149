import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import '../../App.css';
import {Link} from "react-router-dom"
import { useState, useEffect } from "react";
import './Register.css';


class Register extends React.Component {
  constructor(props) {
    super(props);
   this.state = {
     username:"",
    name:"",
    surname:"",
    password:"",
    email:"",
    redirect: false,
    message:'',
    data:[],
    checkedRodo: false,
    checkedSubmit: false, 
    disabled:true,
    showhide:'',
    hidden: true,
    password: ''
  }
  this.handleChangeRodo = this.handleChangeRodo.bind(this); 
}


handleChangeRodo() {
  this.setState({
      checkedRodo: !this.state.checkedRodo     
  })
}

disable=()=>{
  console.log(this.state.checkedRodo)
   
   if(this.state.checkedRodo===true){
     console.log("zaznaczony")
     this.setState({disabled: true})
    
   }
   if(this.state.checkedRodo===false){
     console.log("odznaczony")
     this.setState({disabled: false})
   }
    console.log(this.state.disabled)
    
}

  
      handleSubmit = event => {
      event.preventDefault();
     
    fetch("https://cloverit.pl/api/v1/auth/register", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
        },
        body: JSON.stringify( {username:this.state.username,
                            name:this.state.name,
                            surname:this.state.surname,
                            password:this.state.password,
                            email:this.state.email,
                            message:this.state.message
                          })
    }).then(response =>{
      
      if(response.status===200)
      {
        this.setState({
          redirect: true,
        }); 
        console.log("STATUS 200 OK");
       
      }

      if(response.status===400){
        console.log("STATUS 400 BAD"); 
        alert( response.statusText);
      }
      return response.json();
     
  }).then(function(data){
    const items = data;
    console.log('Błąd: '+  items.status.message);
  })}
  
handleChange = (event) => {
  const state = this.state
  state[event.target.name] = event.target.value;
  this.setState(state);
}

redirect = () =>{
  if(this.state.redirect){
    return<Redirect to={{
      pathname: '/success'
      }} /> 
  }
  }


  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  }
  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  }
      render() {
        return (
         <div className='register-div'>
           <div className='form-register-div'>
          <form onSubmit = { this.handleSubmit }>
              <Link to='/'><img src="/CLOVERIT_NEW_WHITE.png" style={{width: "80%"}}></img></Link>
          
            
          <br></br>   
          <label> E-mail
          <br></br>
            <input  className='input' type = "email" name = "email" onChange= {this.handleChange} style={{ height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}}/>
          </label>
          <br></br> 
          <div className='inside-eye'>
                      <label> Hasło <label id="forgot-password" style={{float:"right", width:"45%"}}> <Link className='link' style={{ textDecoration: 'none' }} to={{pathname: '/forgot-password'}}>Nie pamiętam hasła</Link></label>
                      <br ></br>

                        <input type={this.state.hidden ? 'password' : 'text'} value={this.state.password} onChange={this.handlePasswordChange} className='input' id="password-field"  name = "password"  style={{height:"2.5em",  borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)"}} /> 
                        
                        {
                          this.state.hidden ? <span class="p-viewer"><i toggle="#password-field" class= "fa fa-fw fa-eye field-icon toggle-password" onClick={this.toggleShow}></i></span>
                          : <span class="p-viewer"><i toggle="#password-field" class= "fa fa-fw fa-eye-slash field-icon toggle-password" onClick={this.toggleShow}></i></span>
                        }
                         </label>
                      </div>
          <br></br>
             <select className='select' style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}} onChange={(event) => this.setState({ showhide: event.target.value })} >
             <option value="">Dane abonenta</option>
             <option value="osoba_pv">Osoba prywatna</option>
             <option value="firma">Firma</option>
           </select>
          <br></br>
          {this.state.showhide==='osoba_pv' && (
            <div>
                                  <label> Imię
                                  <br></br>
                                    <input  className='input' type = "text" name = "name" onChange= {this.handleChange} style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}}/>
                                  </label>
                                  <br></br>
                                  <label> Nazwisko
                                  <br></br>
                                    <input  className='input' type = "text" name = "surname" onChange= {this.handleChange} style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}}/>
                                  </label>
                                  <br></br>
                                  <label> Ulica i numer domu
                                  <br></br>
                                    <input  className='input' type = "text" name = "adress" onChange= {this.handleChange} style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}}/>
                                  </label>
                                  <label> Kod pocztowy
                                  <br></br>
                                    <input placeholder="00-000" className='input' type = "text" name = "zipcode" pattern="^\d{2}-\d{3}$" onChange= {this.handleChange} style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}}/>
                                  </label>
                                  <label> Miejscowość
                                  <br></br>
                                    <input  className='input' type = "text" name = "city" onChange= {this.handleChange} style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}}/>
                                  </label>
                                  <br></br>
            </div>
          )}
          {
            this.state.showhide==='firma' && (
              <div>
                <label> Nazwa firmy
                                    <br></br>
                                      <input  className='input' type = "text" name = "firma" onChange= {this.handleChange} style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}}/>
                                    </label>
                                    <label> NIP
                                    <br></br>
                                      <input className='input' type = "text" name = "nip"  onChange= {this.handleChange} style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}}/>
                                    </label>
                                    <label> Imię
                                    <br></br>
                                      <input  className='input' type = "text" name = "name" onChange= {this.handleChange} style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}}/>
                                    </label>
                                    <br></br>
                                    <label> Nazwisko
                                    <br></br>
                                      <input className='input' type = "text" name = "surname" onChange= {this.handleChange} style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}}/>
                                    </label>
                                    <br></br>
                                    <label> Ulica i numer domu
                                    <br></br>
                                      <input  className='input' type = "text" name = "adress" onChange= {this.handleChange} style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}}/>
                                    </label>
                                    <label> Kod pocztowy
                                    <br></br>
                                      <input placeholder="00-000" className='input' type = "text" name = "zipcode" pattern="^\d{2}-\d{3}$" onChange= {this.handleChange} style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}}/>
                                    </label>
                                    <label> Miejscowość
                                    <br></br>
                                      <input className='input' type = "text" name = "city" onChange= {this.handleChange} style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}}/>
                                    </label>
                                        <br></br>
              </div>
            )
          }
          <input type="checkbox" id="checkbox-register" name="checkbox-register" onClick={this.disable} checked={ this.state.checkedRodo} onChange={ this.handleChangeRodo}/>
          <label className='rodo-register' for="checkbox-register" style={{fontSize: "0.9rem", marginBottom:"1%",width:"95%", float:"right"}} >
             Akceptuję <Link className='link' style={{ textDecoration: 'none' }} to={{pathname: '/statute'}}>regulamin</Link> oraz <Link className='link' style={{ textDecoration: 'none' }} to={{pathname: '/privacy_policy'}} >politykę prywatności</Link > panelu klienta, którego właścicielem jest firma Clover IT.</label>
          
          {this.redirect()}
          <button className='login-button' type = "submit" disabled={this.state.disabled} onChange={ this.handleSubmit }  style={{width:"100%", height:"3em",margin:"0 auto", marginTop:"5%", marginBottom:"5%",  left:"50%", display:"block"}}> Zarejestruj się </button>
        </form>
        </div>
        </div>  

        )
      }
    }

  export default Register;

