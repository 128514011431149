import React, { Component } from "react";
import '../../App.css';
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom';
import './ObslugaIT.css';

class   ObslugaIT extends Component {
  render(){
    return (
      <>
      <div className='container-budowa'>
      <img src="/subpages_back_1.jpg" width="100%" className='img'/>
        <div className='image-title'>
           <Fade left>
        <div className='obsluga-img'>
            <img src="/obslugaIT.png" style={{width:"70%"}}></img></div></Fade>
            <Fade right>
        <div className='obsluga-text'>
          <h1 className='h1-title'>Obsługa informatyczna</h1>
          <br></br>
          <br></br>
          <h2 style={{color:"rgb(94, 182, 5)"}}>Pomoc zdalna - maksimum wygody dla firm i osób prywatnych.</h2>
        <br></br>
        <h3> Dzięki narzędziu do komunikacji online, natychmiast możemy Ci pomóc rozwiązać daną usterkę, niezważając na odległość. Dla klientów z Białegostoku oraz okolic w razie potrzeby możemy dotrzeć na miejsce, po uprzednim kontakcie mejlowym bądź telefonicznym.</h3></div></Fade>
     </div>
     
        <div className='obsluga-kafle'>
          <Fade left>
          <div className='kafelIT'>
            <img className='obsluga-kafle-img' src="/best-price.png" ></img>
            <h2>Atrakcyjne ceny</h2>
            <br></br>
            <h3>Zapewniamy pomoc informatyczną w <span style={{color:"rgb(94, 182, 5)"}}>niskiej cenie</span>.</h3>
          </div></Fade>
          <Fade bottom>
          <div className='kafelIT'>
          <img className='obsluga-kafle-img' src="/headphone.png"></img>
            <h2>Pomoc zdalna lub stacjonarna</h2>
            <br></br>
            <h3>Dla szybkosci rozwiązania problemu oferujemy <span style={{color:"rgb(94, 182, 5)"}}>pomoc online </span>. Dla klientów z <span style={{color:"rgb(94, 182, 5)"}}>Białegostoku lub okolic</span> jesteśmy w stanie dojechać <span style={{color:"rgb(94, 182, 5)"}}>na miejsce</span>. </h3>
          </div></Fade>
          <Fade right>
          <div className='kafelIT'>
          <img className='obsluga-kafle-img' src="/quick.png"></img>
            <h2>Szybka reakcja</h2>
            <br></br>
            <h3>Postaramy się jak <span style={{color:"rgb(94, 182, 5)"}}>najszybciej </span>zareagować na zgłaszany problem. </h3>
          </div></Fade>
        </div>
       <div className='obsluga-dlaczego'>
                  <div className='dlaczego-text'>
                  Jeżeli Twoja firma lub Ty jesteś zainteresowany współpracą w zakresie opieki nad systemem IT lub pomocą zdalną  <Link to="/kontakt"><span style={{color:"rgb(94, 182, 5)"}}>napisz lub zadzwoń</span></Link>. 
                  </div>

        </div>
     
      <div className='obsluga-opis-otrzymasz'>
            <Fade left cascade>
              <div className='obsluga-opis-otrzymasz-container'>
                  <h1 style={{color:"rgb(94, 182, 5)"}}>Dlaczego my?</h1>
                  <br></br>
                  <h3><img src="/check.png" width="4%" style={{marginRight:"1%"}}  className='img'/>Gwarantujemy gotowośc 24/7 dla naszych klientów.</h3>
                  <h3><img src="/check.png" width="4%" style={{marginRight:"1%"}}  className='img'/>Oferujemy usługi w atrakcyjnych cenach.</h3>
                  <h3><img src="/check.png" width="4%" style={{marginRight:"1%"}} className='img'/>Zapewniamy prawidłowe działanie urządzeń oraz systemu w firmie i w domu.</h3>
                  <h3><img src="/check.png" width="4%" style={{marginRight:"1%"}}  className='img'/>Zobowiązujemy się do stałego monitoringu systemu dla klientów.</h3>
              </div>  
              </Fade>
          </div> 

     


  

           



       </div>
    </>
  );
    }
}
export default ObslugaIT;