import React, { Component } from "react";
import Loader from "react-loader-spinner";
import '../../App.css';
import './Help.css';

function HelpDeskAPI() {
  return (
    <>
    <div className='container-help'>
      <div className='container-border'>
    <Loader
        type="ThreeDots"
        color="chartreuse"
        height={100}
        width={100}
      />
      <h1>Cierpliwośći, jesteśmy w trakcie tworzenia własnego mechanizmu zdalnej pomocy IT.</h1>
    </div>
    </div>
    </>
  );
}
export default HelpDeskAPI;