import React, { Component } from "react";
import '../../App.css';
import './HostingVPS.css';

class   HostingVPS extends Component {

  render(){
  return (
    <>
     <div className='container-vps'>
     <img src="/subpages_back_1.jpg" width="100%" className='img'/>
     <div className='image-title'>
        <div className='vps-img'>
            <img src="/vps.png" style={{width:"60%"}}></img></div>
           
        <div className='vps-text'>
          <h1 className='h1-title'>Hosting VPS</h1>
          <br></br>
          <br></br>
          <h2 style={{color:"rgb(94, 182, 5)"}}>Wkrótce dostępny...</h2>
        <br></br>
        <h3></h3></div>
        </div>
       </div>
    </>
  );
}
}
export default HostingVPS;