import React, { Component } from "react";
import '../../App.css';
import './HostingWWW.css';
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom';
class   HostingWWW extends Component {

   
  render(){
  return (
    <>
        <div className='container-budowa'>
        <img src="/subpages_back_1.jpg" width="100%" className='img'/>
        <div className='image-title'>
              <Fade left>
        <div className='hosting-img'>
            <img src="/hosting.png" style={{width:"70%"}}></img></div></Fade>
            <Fade right>
        <div className='hosting-text'>
          <h1 className='h1-title'>Hosting WWW</h1>
          <br></br>
          <br></br>
          <h2 style={{color:"rgb(94, 182, 5)"}}>Tani, szybki hosting ze stałym wsparciem dla klientów.</h2>
        <br></br>
        <h3> Oferujemy tani, a zarazem niezawodny hosting stron WWW. Nasza oferta skierowana jest zarówno dla małych jak i dużych stron oraz sklepów internetowych. Wszystkie pakiety hostingowe posiadają bezpłatne certyfikaty SSL, a także codzienny backup plików.</h3></div></Fade>
        </div>
        <div className='hosting-table'>
        <table>
        <colgroup>
    <col span="2" />
    <col style={{backgroundColor:"rgb(224, 224, 224)",  border: "7px solid #4CAF50"}}/>
  </colgroup>
              <tr>
                <th className='th-title'>Hosting dla Ciebie</th>
                <th className='th-price'>Basic</th>
                <th className='th-price'>Standard</th>
                <th className='th-price'>Premium</th>
              </tr>
              <tr>
                <td className='th-title'><sup>1</sup> Cena/ Rok</td>
                <td className='th-price'>250 zł</td>
                <td className='th-price'>430 zł</td>
                <td className='th-price'>620 zł</td>
              </tr>
              <tr>
                <td className='th-title'><sup>2</sup> Cena promocyjna/ Rok</td>
                <td>45 zł</td>
                <td>50 zł</td>
                <td>75 zł</td>
              </tr >
              <tr>
                <td className='th-title'>Liczba domen</td>
                <td>bez limitu</td>
                <td>bez limitu</td>
                <td>bez limitu</td>
              </tr>
              <tr>
                <td className='th-title'>Pojemność</td>
                <td>25 GB</td>
                <td>100 GB</td>
                <td>300 GB</td>
              </tr>
              <tr>
                <td className='th-title'>Transfer</td>
                <td>bez limitu</td>
                <td>bez limitu</td>
                <td>bez limitu</td>
              </tr>
              <tr>
                <td className='th-title'>Liczba baz danych</td>
                <td>5</td>
                <td>bez limitu</td>
                <td>bez limitu</td>
              </tr>
              <tr>
                <td className='th-title'>Liczba kont baz danych</td>
                <td>5</td>
                <td>bez limitu</td>
                <td>bez limitu</td>
              </tr>
              <tr>
                <td className='th-title'>Liczba kont email</td>
                <td>5</td>
                <td>bez limitu</td>
                <td>bez limitu</td>
              </tr>
              <tr>
                <td className='th-title'>Backup</td>
                <td>4 tygodniowo</td>
                <td>7 tygodniowo</td>
                <td>7 tygodniowo</td>
              </tr>
              <tr>
                <td className='th-title'>Certyfikat SSL (Let's encrypt)</td>
                <td>tak</td>
                <td>tak</td>
                <td>tak</td>
              </tr>
              <tr>
                <td className='th-title'></td>
                <td> <Link to="/kontakt"><button className='hosting-button' type="button" >Zamów</button></Link></td>
                <td><Link to="/kontakt"><button className='hosting-button' type="button" >Zamów</button></Link></td>
                <td><Link to="/kontakt"><button className='hosting-button'  type="button" >Zamów</button></Link></td>
              </tr>
            </table>


        </div>
        <div className='hosting-cena'>
          <h4><sup>1</sup> Podane ceny są cenami brutto.</h4>
          <h4><sup>2</sup> Ceny promocyjne obowiązują w pierwszym okresie rozliczeniowym. Następne odnowienia są możliwe w cenach regularnych.</h4>
        </div>


        <div className='hosting-kafle'>
          <Fade left>
          <div className='kafel'>
            <img className='hosting-kafle-img' src="/support.png" ></img>
            <h2>Wsparcie 24/7</h2>
            <br></br>
            <h3>Zapewniamy <span style={{color:"rgb(94, 182, 5)"}}>pomoc zdalną</span> dla klientów, najszybciej jak to będzie możliwe. Napisz do nas lub zadzwoń.</h3>
          </div></Fade>
          <Fade bottom>
          <div className='kafel'>
          <img className='hosting-kafle-img' src="/backup.png"></img>
            <h2>Backup</h2>
            <br></br>
            <h3>Przechowujemy kopie plików w zależności od wybranego pakietu <span style={{color:"rgb(94, 182, 5)"}}>(4 lub 7 tygodniowo)</span>. Dzięki czemu w razie potrzeby odzyskasz swoje najnowsze zmiany na stronie.</h3>
          </div></Fade>
          <Fade right>
          <div className='kafel'>
          <img className='hosting-kafle-img' src="/email.png"></img>
            <h2>Poczta</h2>
            <br></br>
            <h3>Oferujemy <span style={{color:"rgb(94, 182, 5)"}}>stworzenie adresu e-mail</span> z posiadanej domeny strony.</h3>
          </div></Fade>
        </div>


       </div>
    </>
  );
}
}
export default HostingWWW;