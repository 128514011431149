import React, { Component } from "react";
import '../../App.css';
import {Link} from "react-router-dom"
import Status from "./Status";
import './Login.css';

class Login extends React.Component {
  state = {
          data: [],
          status: new Status(),
          hidden: true,
          password: ''
        }  



        componentDidMount = ()=>{
            fetch("https://cloverit.pl/api/v1/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body:JSON.stringify( {"username": "anowak",
                "password": "anowak"}),
                redirect: 'follow'

            
            }).then(function(response) {

                console.log(response.json());
                return response.json();
            });

            if (this.password) {
              this.setState({ password: this.password });
            }
            
        }

        handleChange = (event) => {
          const state = this.state
          state[event.target.name] = event.target.value;
          this.setState(state);
        }

        handlePasswordChange = (e) => {
          this.setState({ password: e.target.value });
        }
        toggleShow = () => {
          this.setState({ hidden: !this.state.hidden });
        }

              render() {
                return (
                 <div className='login-div'>
                  <form onSubmit = { this.handleSubmit } >
                  <Link to='/'><img src="/CLOVERIT_NEW_WHITE.png" style={{width: "80%"}}></img></Link>
          
                    <br></br>
                      <label> Nazwa użytkownika
                      <br></br>
                        <input  className='input' type = "text" name = "username"  style={{ height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}} onChange= {this.handleChange}/>
                      </label>
                      <br></br>
                      <div className='inside-eye'>
                      <label> Hasło <label  style={{float:"right", width:"45%"}}> <Link className='link' style={{ textDecoration: 'none' }} to={{pathname: '/forgot-password'}}>Nie pamiętam hasła</Link></label>
                      <br ></br>

                        <input type={this.state.hidden ? 'password' : 'text'} value={this.state.password} onChange={this.handlePasswordChange} className='input' id="password-field"  name = "password"  style={{width:"100%",height:"2.5em",  borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)"}} /> 
                        
                        {
                          this.state.hidden ? <span class="p-viewer"><i toggle="#password-field" class= "fa fa-fw fa-eye field-icon toggle-password" onClick={this.toggleShow}></i></span>
                          : <span class="p-viewer"><i toggle="#password-field" class= "fa fa-fw fa-eye-slash field-icon toggle-password" onClick={this.toggleShow}></i></span>
                        }
                         </label>
                      </div>
                      <br></br>
     
                      
                      
                      <button className='login-button' type = "submit" style={{width:"100%", height:"3em",margin:"0 auto", marginTop:"5%", marginBottom:"5%",  left:"50%", display:"block"}}> Zaloguj się </button>
                      </form>
                      <h3  style={{textAlign:"center"}}>Nie masz jeszcze konta?</h3>
                      <Link className='link' style={{ textDecoration: 'none' }} to={{pathname: '/register'}} > <h3  style={{textAlign:"center"}}>Załóż konto</h3></Link>
                </div>
                )
              }
            }
        
          export default Login;