import React from 'react';
import '../App.css';
import './Footer.css';
import {Link} from 'react-router-dom';

function Footer() {
  return (
      <div className='container-footer'>
            <div className='div-footer'>
                  <div className='div-text'>
                    <img src="/phone-green.png" width="10%" className='img'/><h1>Kontakt telefoniczny</h1><h3 style={{color:'white'}}><a href="tel:798 658 700">798 658 700</a></h3></div>
                  </div>
            <div className='div-footer'><img src="/CLOVERIT_NEW.png" width="75%" className='footer-logo' /></div>
            <div className='div-footer'>
            <div className='div-text'>
                  <img src="/mail-green.png" width="10%"  className='img'/><h1>Kontakt mailowy</h1>
                  <h3 style={{color:'white'}}> <a href="mailto:kontakt@cloverit.pl">kontakt@cloverit.pl</a></h3>
                  </div></div>
            
          <div className='div-copy' style={{color:'white',marginBottom:"2%"}}>
                 <hr className='footer-hr'></hr> 
                 <div className='menu-footer'>
                    <ul >
                      <li >
                      <Link to='/regulamin' className='footer-links' >
                            Regulamin
                        </Link>
                      </li>
                      <li>
                      <Link to='/polityka_prywatnosci' className='footer-links' >
                            Polityka prywatności
                        </Link>
                      </li>
                      <li>
                      <Link to='/kontakt' className='footer-links'>
                            Kontakt
                        </Link>
                      </li>
                      <li>
                      <Link to='/helpAPI'className='footer-links' >
                            Help desk
                        </Link>
                      </li>  
                      <li style={{float:"right"}}>
                      &copy; Copyright 2022 CloverIT
                      </li>  
                    </ul> 
                       
                     
                  </div>
   </div>
      </div>
    );
}

export default Footer;