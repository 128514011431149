import React, { Component } from "react";
import '../../App.css';
import './StronyWWW.css';
import Fade from 'react-reveal/Fade';
import {Helmet} from "react-helmet";
import Pulse from 'react-reveal/Pulse';
import { FloatingLettersTextBuilder } from 'react-animated-text-builders'
import { BlinkingCursorTextBuilder } from 'react-animated-text-builders'
import {Link} from "react-router-dom"

function StronyWWW() {
  
  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
                <meta name="keywords" content="budowa stron www, sklepy e-commerce, sklepy internetowe, tworzenie stron, pozycjonowanie stron, SEO, wizytówka Google, prosta strona, rozbudowana strona, responzywna strona"/>
                
     </Helmet>
    <div className='container-budowa'>
        <img src="/subpages_back_1.jpg" width="100%" className='img'/>
    <div className='image-title'>
           
                <Fade left>
                <div className='img-icon'>
                <img src="/icon-www.png" style={{width:"70%"}}></img>
                  </div>
                   </Fade>

                  <Fade right>
              <div className='img-text'>
                <br></br> 
                <h1 className='h1-title'>Tworzenie stron WWW</h1>
                <br></br>
                <br></br>
                <h2 style={{color:"rgb(94, 182, 5)"}}>Wszystkie stworzone przez nas strony są objęte stałą opieką.</h2>
                <br></br>
                <h3>Strona internetowa w obecnych czasach to wirtualna wizytówka Twojej firmy, Twojej osoby. Dobrze zaprojektowana strona przyciąga uwagę oraz buduje wizerunek. Pomożemy stworzyć stronę oraz gwarantujemy w cenie projektu, realizację wizytówki Google oraz podstawowe pozycjonowanie strony w wyszukiwarkach. </h3>
         
              </div>
           </Fade> 
           </div>
        <div className='opis-budowa-container'>
          <div className='opis-budowa'>
            <div className='opis-budowa-mini'>
            <img src="/website.png" width="20%"  style={{marginLeft: '40%'}} className='img'/>
                  <h3>Tworzymy strony internetowe od podstaw w technologii <span style={{color:"rgb(94, 182, 5)"}}>React</span> jako SPA (Single Page Aplication). </h3>
                  <h3>Zapewniamy dalszą rozbudowę (wraz ze wzrostem potrzeb Klienta bądź firmy) oraz aktualizację treści na stronie. </h3>
            </div>
            <div className='opis-budowa-mini'>
            <img src="/cart.png" width="20%"  style={{marginLeft: '40%'}} className='img'/>
              <h3>Tworzymy zarówno prostsze strony (wizytówki) jak i bardziej rozbudowane oraz <span style={{color:"rgb(94, 182, 5)"}}>sklepy internetowe e-commerce.</span> w których będziesz mógł prowadzić sprzedż swoich produktów i usług. </h3>
            </div>
            <div className='opis-budowa-mini'>
            <img src="/searching-bar.png" width="20%"  style={{marginLeft: '40%'}} className='img'/>
              <h3>Dla każdej stworzonej witryny zapewnimy podstawowe <span style={{color:"rgb(94, 182, 5)"}}>pozycjonowanie</span> w wyszukiwarkach internetowych, dla lepszej widoczności strony w sieci</h3>
               <h3>Pomożemy w zakupie oraz rejestracji domeny, dla naszych klientów zapewniamy hosting stworzonej strony.</h3>
            </div>
            <div className='opis-budowa-mini'>
            <img src="/help.png" width="20%"  style={{marginLeft: '40%'}} className='img'/>
              <h3>Zapewniamy opiekę nad stroną, w razie potrzeby wniesiemy poprawki lub zmienimy całą koncepcję witryny. Pomożemy Ci zaoszczędzić czas oraz pieniądze.</h3>
            </div>
            <div className='opis-budowa-mini'>
            <img src="/business-card.png" width="20%"  style={{marginLeft: '40%'}} className='img'/>
              <h3>Zapewniamy stworzenie <span style={{color:"rgb(94, 182, 5)"}}>wizytówki Google My Bussines</span> oraz konkretnych social mediów pod preferencje klienta.
Wizytówka Google My Bussines pozwoli na dostarczeniu użytkownikom przydatnych informacji o Twojej firmie oraz usługach, pozwoli na szybsze i efektywniejsze wyszukanie tych informacji w Mapach Google. Tworzymy również adresy e-mail pod domene strony.</h3>
            </div>
            <div className='opis-budowa-mini'>
            <img src="/responsive.png" width="20%"  style={{marginLeft: '40%'}} className='img'/>
              <h3>Nasze strony są <span style={{color:"rgb(94, 182, 5)"}}>w pełni responsywne</span>, co pozwala wyświetlać treści zachowując estetyczny wygląd na urządzeniach mobilnych.  </h3>
            </div>
               </div>
          </div>



          <div className='opis-otrzymasz'>
            <Fade left cascade>
              <div className='opis-otrzymasz-container'>
                  <h1 style={{color:"rgb(94, 182, 5)"}}>Co otrzymujesz?</h1>
                  <br></br>
                  <h3><img src="/check.png" width="4%" style={{marginRight:"1%"}}  className='img'/>Responsywność</h3>
                  <h3><img src="/check.png" width="4%" style={{marginRight:"1%"}}  className='img'/>Adres e-mail z domeny strony</h3>
                  <h3><img src="/check.png" width="4%" style={{marginRight:"1%"}} className='img'/>Oryginalą grafikę</h3>
                  <h3><img src="/check.png" width="4%" style={{marginRight:"1%"}}  className='img'/>Podstawowe pozycjonowanie SEO</h3>
                  <h3><img src="/check.png" width="4%" style={{marginRight:"1%"}}  className='img'/>Zapewniony hosting</h3>
                  <h3><img src="/check.png" width="4%" style={{marginRight:"1%"}}  className='img'/>Bezpieczeństwo domeny</h3>
                  <h3><img src="/check.png" width="4%" style={{marginRight:"1%"}}  className='img'/>Opiekę nad stroną 24/7</h3>
                  <h3><img src="/check.png" width="4%" style={{marginRight:"1%"}}  className='img'/>Stworzenie wizytówki Google My Buisness</h3>
              </div>  
              </Fade>
          </div>  

          <div className='strony'>
              <div className='strony-container-1'>
                <div className='strony-container-2'>
                    <img src="/strony internetowe.jpg" width="80%"  className='img'/></div>
                    <div className='strony-container-2'>
                    <h1 style={{color:"rgb(94, 182, 5)"}}>Prosta strona internetowa</h1>
                    <br></br>
                    <h3>Strona internetowa typu wizytówka dla większej zauważalności w sieci, bądź strona typu landing page. Idealna opcja dla ludzi oraz firm, które chcą zaprezentowć się w prosty sposób - w jednym oknie umieszczone najważniejsze informacje, usługi bądź zdjęcia.</h3>
                    <br></br>
                    <h3 >Strona składa się z maksymalnie 4 podstron.</h3>
                    <br></br>
                    <h3 >Czas realizacji: <span style={{color:"rgb(94, 182, 5)"}}> 7 - 10 dni </span> </h3>
                    <br></br>
                    <h3><span style={{color:"rgb(94, 182, 5)"}}>Zainteresowany?</span></h3>
                    <Link to="/kontakt"><button type="button" > Skontaktuj się z nami</button></Link>
                    </div>
              </div>

              <div className='strony-container-1'>
                
                    <div className='strony-container-2'>
                    <h1 style={{color:"rgb(94, 182, 5)"}}>Rozbudowana strona internetowa</h1>
                    <br></br>
                    <h3>Wariant strony z rozbudowanymi funkcjonalnościami - formularz kontaktowy, kalendarz rezerwacji, mapa Google itp.</h3>
                    <h3>Nieograniczona ilość podstron.</h3>
                    <br></br>
                    <h3 >Czas realizacji: <span style={{color:"rgb(94, 182, 5)"}}> 14 dni </span></h3>
                    <br></br>
                    <h3><span style={{color:"rgb(94, 182, 5)"}}>Zainteresowany?</span></h3>
                    <Link to="/kontakt"><button type="button" > Skontaktuj się z nami</button></Link>
                      </div>
                      <div className='strony-container-2'>
                    <img src="/domenico-loia-hGV2TfOh0ns-unsplash.jpg" width="80%"  className='img'/>
                    </div>
                </div>

                <div className='strony-container-1'>
                      <div className='strony-container-2'>
                      <img src="/sklepy internetowe.png" width="80%" className='img'/>
                      </div>
                        <div className='strony-container-2'>
                          <h1 style={{color:"rgb(94, 182, 5)"}}>Sklep internetowy e-commerce</h1>
                          <br></br>
                        <h3>Oferujemy zaprojektowanie i stworzenie własnego, łatwo zarządzalnego sklepu internetowego z nieograniczoną ilością produktów.</h3>
                        <br></br>
                    <h3 >Czas realizacji:<span style={{color:"rgb(94, 182, 5)"}}> do 30 dni</span></h3>
                    <br></br>
                    <h3><span style={{color:"rgb(94, 182, 5)"}}>Zainteresowany?</span></h3>
                    <Link to="/kontakt"><button type="button" > Skontaktuj się z nami</button></Link>
                        </div>
                </div>

          </div>  

       <div className='container-www'>
      <div className='budowa'>
        <h1>Portfolio</h1>
        <div className='portfolio-img' >

              <div class="container">
              <img src="gabinet_desktop.png" alt="Avatar" className="image"/>
                <div class="overlay">
                  <a href="https://gab-lek.bialystok.pl/"><div class="text">Gabinet Lekarski Białystok <p>gab-lek.bialystok.pl</p></div></a>
                </div>
              </div>

              <div class="container">
              <img src="rzeczoznawca.png" alt="Avatar" className="image"/>
                <div class="overlay">
                <a href="https://rzeczoznawca-bialystok.pl/"><div class="text">Rzeczoznawca Białystok <p>rzeczoznawca-bialystok.pl</p></div></a>
                </div>
              </div>
              <div class="container">
              <img src="sloneczka.png" alt="Avatar" className="image"/>
                <div class="overlay">
                <a href="https://sloneczka.bialystok.pl/"><div class="text">Przedszkole Słoneczka <p>sloneczka.bialystok.pl/</p></div></a>
                </div>
              </div>
              <div class="container">
              <img src="hami.png" alt="Avatar" className="image"/>
                <div class="overlay">
                <a href="https://hami-landscape.pl/"><div class="text">Architekt Krajobrazu Michał Hajdowski <p>hami-landscape.pl/</p></div></a>
                </div>
              </div>

              </div>     
      </div>
      </div>
      </div>

      
  
    </>
  );
}
export default StronyWWW;