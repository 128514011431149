import React, { Component } from "react";
import '../../App.css';
import Hero from '../Hero';
import HeroIcons from '../HeroIcons';

function Home() {
  return (
    <>
      <Hero/>
      <HeroIcons/>
    </>
  );
}
export default Home;