import React, { Component } from "react";
import '../../App.css';
import { useState, useEffect } from "react";
import {Link} from "react-router-dom"
import './ForgotPass.css';

class Login extends React.Component {

    render() {
        return (
            <div className='forgot-pass-back-div'>
             <div className='forgot-div'>
              <form onSubmit = { this.handleSubmit } >
                <br></br>
                <h1>Nie pamiętasz hasła?</h1>
                <h3>Podaj adres e-mail użyty do rejestracji konta. </h3>
                <br></br>
                  <label> E-mail
                  <br></br>
                    <input  className='input' type = "email" name = "email"  style={{width:"100%", height:"2.5em", borderRadius: "5px", border:" 2px solid rgb(182, 182, 182)", marginBottom:"5%"}} onChange= {this.handleChange}/>
                  </label>
                  <br></br>
                 
                  <button className='login-button' type = "submit" style={{width:"100%", height:"3em",margin:"0 auto", marginTop:"5%", marginBottom:"5%",  left:"50%", display:"block"}}> Zmień hasło </button>
                  </form>
                
            </div> </div>
    )
}
}

export default Login;