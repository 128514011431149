import React from 'react';
import '../App.css';
import { Button } from './Button';
import './Hero.css';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';


function Hero() {
  return (
    <div className='hero-container'>
        <img src="/heroimage2_4k_3.png" width="100%" className='img'/>
        <div className='container'>
      <h2>Oferujemy kompleksową obsługę IT</h2>
      <br></br>
      <p>Tworzymy strony internetowe</p>
      <p>Zapewniamy hosting domen</p>
      <p>Udzielamy pomocy wszystkim klientom i firmom</p>
      <Fade left>
      <div className='hero-btns'>
      <Link to='/kontakt' >
        <Button
          className='btns'
          buttonStyle='btn--outline'
        >
          KONTAKT 
        </Button>
        </Link>
       </div>
       </Fade>
       <div className='arrow bounce'>
                <a class="fa fa-arrow-down fa-2x" href="#h-icons"></a>
              </div>
      </div>

  
    </div>
  );
}

export default Hero;